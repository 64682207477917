var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "callAnswerLogContainerFlexColumn" },
    [
      _c(
        "div",
        { staticClass: "callAnswerLogContainerFlexColumn__top" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("电话接通日志")]),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "dc-search-panel",
        {
          staticStyle: {
            border: "none",
            "background-color": "white",
            "margin-bottom": "0",
          },
          attrs: { params: _vm.queryParams },
          on: { search: _vm.doSearch, reset: _vm.reset },
        },
        [
          _c(
            "dc-form-item",
            { attrs: { label: "接通时间(起)" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.queryParams.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "startDate", $$v)
                  },
                  expression: "queryParams.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "dc-form-item",
            { attrs: { label: "接通时间(止)" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.queryParams.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "endDate", $$v)
                  },
                  expression: "queryParams.endDate",
                },
              }),
            ],
            1
          ),
          _c("dc-input", { attrs: { field: "username", label: "客服名称" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getNowDay } },
            [_vm._v("当天")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.getMonth } },
            [_vm._v("当月")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.doSearch } },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.reset } },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "callAnswerLogContainerFlexColumn__body" },
        [
          _c(
            "dc-datatable",
            {
              ref: "list",
              attrs: { url: _vm.apiUrl, params: _vm.queryParams },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "username",
                  width: "120",
                  label: "客服名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "agent",
                  width: "80",
                  label: "所属分机",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userno",
                  width: "100",
                  label: "用户号码",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "answertime",
                  width: "120",
                  label: "时长",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFilter")(scope.row.answertime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "serviceDirect",
                  label: "呼叫类型/方向",
                  align: "center",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getServiceDirectText")(
                                scope.row.servicedirect
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "callid", label: "呼叫标示", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taskid",
                  label: "外呼任务号",
                  align: "center",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "av",
                  label: "外线音视频",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("getAvText")(scope.row.av)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "tc",
                  label: "外线终端能力",
                  align: "center",
                  width: "85",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("getTcText")(scope.row.tc)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }